import React, { PropsWithChildren } from 'react';
import { useFranchise } from '~/shared/utils';
// eslint-disable-next-line no-restricted-imports
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

type Props = PropsWithChildren<NextLinkProps>;

export const RouterLink = ({
    href,
    prefetch,
    replace,
    scroll,
    shallow,
    locale,
    children,
    passHref = true,
}: Props) => {
    const { isMultiCultural } = useFranchise();

    return (
        <NextLink
            href={href}
            prefetch={prefetch}
            replace={replace}
            scroll={scroll}
            shallow={shallow}
            locale={isMultiCultural ? locale : false}
            passHref={passHref}
            legacyBehavior
        >
            {children}
        </NextLink>
    );
};

import React, { PropsWithChildren } from 'react';

interface WrapProps {
    if: boolean;
    wrap: (children: React.ReactNode) => JSX.Element;
}

export const ConditionalWrap = ({
    if: condition,
    wrap,
    children,
}: PropsWithChildren<WrapProps>) => {
    return condition ? wrap(children) : <>{children}</>;
};

import React from 'react';
import { Link, VideoMedia } from '~/lib/data-contract';
import { useCookieConsent } from '$shared/hooks';
import { Video } from '$shared/components';
import { useRouter } from 'next/router';

type M11VideoPropsType = {
    hasTextContent: boolean;
    video?: VideoMedia;
    imageSrc?: string;
    callToAction?: Link;
    posterSizes?: string;
};
export const M11HeroVideo = ({
    video,
    hasTextContent,
    imageSrc,
    callToAction,
    posterSizes,
}: M11VideoPropsType) => {
    const router = useRouter();
    const { marketing, statistic } = useCookieConsent();
    const shouldLinkCover = Boolean(!video?.controls && callToAction?.url);

    const handleHeroClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        if (callToAction?.url) {
            router.push(callToAction.url);
        }
    };

    return (
        <>
            {video?.src &&
                ((marketing && statistic) || (!(marketing && statistic) && !imageSrc)) && (
                    <Video
                        controls={false}
                        src={video.src}
                        loop={video.loop}
                        playing={video.autoPlay}
                        posterSrc={imageSrc}
                        cover={true}
                        buttonPosition={hasTextContent ? 'corner' : 'center'}
                        showCustomButtons={video.controls}
                        onOverlayClick={shouldLinkCover ? handleHeroClick : undefined}
                        posterSizes={posterSizes}
                    />
                )}
        </>
    );
};

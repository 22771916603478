import { Category, MediaAndTextItem, PageReference } from '~/lib/data-contract';

/**
 * Maps reference items to media and text items
 *
 * @param items mixed models of IMediaAndTextItem & IPageReference
 * @returns Array of IMediaAndTextItem
 */
export const itemMapper = (
    items?: (MediaAndTextItem | PageReference | Category)[]
): MediaAndTextItem[] | undefined => {
    return items?.map((item): MediaAndTextItem | PageReference | Category => {
        if (isPageReference(item)) {
            return {
                headline: item.pageTitle || item.link?.text,
                description: `${item.pageDescription}`,
                image: item.pageImage,
                ...item,
                alias: 'contentArticle',
            };
        }

        if (isCategory(item)) {
            return {
                headline: item.title,
                description: item.text,
                image: item.image,
                ...item,
                alias: 'contentArticle',
            };
        }

        return item;
    });
};

const isPageReference = (
    item: MediaAndTextItem | PageReference | Category
): item is PageReference => {
    return item.alias === 'pageReference';
};

export const isCategory = (
    category: MediaAndTextItem | PageReference | Category
): category is Category => {
    return category.alias === 'category' || category.alias === 'categorySimple';
};

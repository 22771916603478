import { CSSObject } from '@emotion/react';
import { cover } from '~/shared/utils/styled/cover';

/**
 * Safely inserts aspect ratio if supported by browser,
 * Be aware using this in non supported browsers will force
 * absolute position on direct children and stretch it
 */
export const aspectRatio = (height: number, width: number): CSSObject => ({
    ['@supports (aspect-ratio: auto)']: {
        aspectRatio: `${height} / ${width}`,
    },
    ['@supports not (aspect-ratio: auto)']: {
        paddingTop: '100%',
        height: '0',
        position: 'relative',
        overflow: 'hidden',
        '& > *': {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            position: 'absolute !important' as any,
            ...cover,
        },
    },
});

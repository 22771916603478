import { useEffect, useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import { StyledLineSpinner, StyledLineSpinnerProps as StyledProps } from './styled';

type StyledLineSpinnerProps = Pick<StyledProps, 'delay' | 'shade' | 'width'>;

export type LineSpinnerProps = {
    mode?: 'default' | 'inView';
} & StyledLineSpinnerProps;

export const LineSpinner = ({ mode = 'default', ...props }: LineSpinnerProps) => {
    switch (mode) {
        case 'inView':
            return <InViewLineSpinner {...props} />;
        case 'default':
        default:
            return <StyledLineSpinner active={true} {...props} />;
    }
};

const InViewLineSpinner = (props: StyledLineSpinnerProps) => {
    const spinner = useRef<HTMLSpanElement | null>(null);
    const intersection = useIntersection(spinner, { threshold: 1, rootMargin: '100px 0px' });
    const [hasIntersected, setHasIntersected] = useState(false);

    useEffect(() => {
        if (intersection?.isIntersecting && !hasIntersected) {
            setHasIntersected(true);
        }
    }, [intersection?.isIntersecting, hasIntersected, setHasIntersected]);

    return <StyledLineSpinner ref={spinner} active={hasIntersected} {...props} />;
};

import { CSSObject } from '@emotion/react';
import { transparentize } from 'color2k';
import { cover } from '~/shared/utils/styled';

/**
 * Creates a linear background from bottom that fades to top
 * Must have a relative container
 */
export const linearBackground = (background = '#000'): CSSObject => ({
    ...cover,
    background: `linear-gradient(180deg, ${transparentize(background, 1)} 0%, ${transparentize(
        background,
        0.5
    )} 100%);`,
});

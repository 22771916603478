import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export type StyledLineSpinnerProps = {
    delay?: number;
    active?: boolean;
    shade?: 'light' | 'dark';
    width?: string | number;
};

const LINE_SPINNER_ANIMATION = keyframes({
    '0%': { transform: 'scaleX(0)', transformOrigin: 'left' },
    '50%': { transform: 'scaleX(1)', transformOrigin: 'left' },
    '51%': { transform: 'scaleX(1)', transformOrigin: 'right' },
    '100%': { transform: 'scaleX(0)', transformOrigin: 'right' },
});

export const StyledLineSpinner = styled.span<StyledLineSpinnerProps>(
    ({ theme: { animations, traits }, delay = 0, active, shade = 'dark', width = 50 }) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 2,
        width: width,
        maxWidth: '50%',

        '&:after': {
            content: '""',
            display: active ? 'block' : 'none',
            height: '100%',
            width: '100%',
            backgroundColor: traits.lineSpinner[shade],
            animationDuration: `${animations.durations['slow01'] * 2}s`,
            animationTimingFunction: animations.getEasing('reversible', 'expressive'),
            animationDirection: 'alternate',
            animationIterationCount: 'infinite',
            animationDelay: `${delay}s`,
            animationFillMode: 'both',
            animationName: active ? LINE_SPINNER_ANIMATION : 'none',
        },
    })
);

import { ImageLoader, ImageLoaderProps } from 'next/legacy/image';

export type DigizuiteImageProps = {
    function: 'resize';
    mimeType: 'image/png' | 'image/jpeg' | 'image/svg+xml';
    destinationId: string;
    height?: string | number;
    width?: string | number;
    assetId?: number;
    aspectType?: '0' | '1' | '2' | '3';
    quality?: string;
    // Be very cautious using force since this can/will clear the cache in 10 minutes
    // and generate a new image
    force?: 'true' | 'false';
    lastUpdated?: string;
    x?: number;
    y?: number;
    gravity?:
        | 'Northwest'
        | 'North'
        | 'Northeast'
        | 'West'
        | 'Center'
        | 'East'
        | 'Southwest'
        | 'South'
        | 'Southeast';
    cH?: number;
    cW?: number;
};

type CustomUmbracoImageProps = {
    focalPoint?: [number, number];
    dimensions?: [number, number];
};

export type DigizuiteImageLoaderProps = Partial<
    Pick<
        DigizuiteImageProps,
        'mimeType' | 'aspectType' | 'gravity' | 'height' | 'cW' | 'cH' | 'assetId'
    >
> &
    CustomUmbracoImageProps;

type GetCropParamsProps = CustomUmbracoImageProps &
    Pick<DigizuiteImageProps, 'gravity'> &
    Required<Pick<DigizuiteImageProps, 'cW' | 'cH'>> &
    Pick<ImageLoaderProps, 'width'>;

const normalizeSrc = (src: string) => {
    return src[0] === '/' ? src.slice(1) : src;
};

const getCropParams = ({
    dimensions,
    focalPoint,
    width,
    cW,
    cH,
    gravity,
}: GetCropParamsProps): URLSearchParams => {
    const params = new URLSearchParams({});
    const cropRatio = cW / cH;
    const cropHeight = width / cropRatio;

    params.append('cH', String(Math.floor(cropHeight)));
    params.append('cW', String(width));

    // If gravity exists, ignore focalPoint.
    // If neither exists, set gravity to 'Center'
    if (gravity) {
        return params;
    } else if (!(focalPoint && dimensions)) {
        params.append('gravity', 'Center');
        return params;
    }

    const [imageW, imageH] = dimensions;
    const [posX, posY] = focalPoint;
    const dimensionsRatio = imageW / imageH;
    const isHorizontalCrop = cropRatio < dimensionsRatio;

    // If cropRatio is smaller than dimensionsRatio, we know to crop on the horizontal plane, i.e. remove from sides.
    // Else crop on vertical plane, i.e. remove from top and bottom
    if (isHorizontalCrop) {
        const originalWidth = cropHeight * dimensionsRatio;
        const focalWidth = originalWidth * posX;
        const maxOffsetX = originalWidth - width;
        const offsetX = focalWidth - width / 2;
        const safeOffsetX = Math.round(Math.max(Math.min(offsetX, maxOffsetX), 0));
        params.append('x', String(safeOffsetX));
    } else {
        const originalHeight = width / dimensionsRatio;
        const focalHeight = originalHeight * posY;
        const maxOffsetY = originalHeight - cropHeight;
        const offsetY = focalHeight - cropHeight / 2;
        const safeOffsetY = Math.round(Math.max(Math.min(offsetY, maxOffsetY), 0));
        params.append('y', String(safeOffsetY));
    }
    return params;
};

// Digizuite "docs" https://digizuite.atlassian.net/wiki/spaces/DD/pages/710869001/How+to+use+assetstreamer+stream+qualities+download+source+file
export const digizuiteLoader = ({
    height,
    mimeType = 'image/jpeg', // Digizuite does not support webp via assetstreamer
    aspectType = '1',
    cH,
    cW,
    gravity,
    focalPoint,
    dimensions,
}: DigizuiteImageLoaderProps): ImageLoader => {
    const digizuiteParams = new URLSearchParams({
        function: 'resize',
        mimeType,
        aspectType,
        ...(height && { height: String(height) }),
        ...(gravity && { gravity }),
    });

    return ({ src, width, quality }: ImageLoaderProps): string => {
        const imageParams = new URLSearchParams({
            quality: String(quality),
        });

        // If cW and cH is supplied we must crop.
        // To enable crop (Digizuite does it this way), we omit sending the width param, and instead use cW and cH
        if (cW && cH) {
            digizuiteParams.delete('height'); // Remove height as well
            const cropParams = getCropParams({ dimensions, focalPoint, width, cW, cH, gravity });
            for (const [key, value] of cropParams.entries()) {
                imageParams.append(key, value);
            }
        } else {
            imageParams.append('width', String(width));
        }

        return `${normalizeSrc(src)}&${digizuiteParams.toString()}&${imageParams.toString()}`;
    };
};

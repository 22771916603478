import isPropValid from '@emotion/is-prop-valid';

/**
 * Used combined with styled components to prevent props from being forwarded to the DOM element.
 *
 * @example
 * type Props = {
 *   loading: boolean
 * }
 * const StyledDiv = styled('div', {
 *   shouldForwardProp: shouldNotForwardProps(['loading']),
 * })<Props>(....)
 */
export const shouldNotForwardProps = (excludeProps: string[]) => (propName: PropertyKey) => {
    const propNameString = String(propName);
    return isPropValid(propNameString) && !excludeProps.includes(propNameString);
};

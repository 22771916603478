import { Link as LinkType } from '~/lib/data-contract';
import {
    ButtonProps,
    TextVariants,
    Text,
    StrokeText,
    RouterLink,
    Button,
} from '~/shared/components';
import { GeneratedThemeShade } from '~/theme';
import { StyledTextBlockForContentCards } from './styled';
import { useTheme } from '@emotion/react';

export type TextBlockForContentCardsLayout = 'on' | 'below';

export type TextBlockForContentCardsProps = {
    /**
     * The theme of the module/context in which this textBlock is used
     */
    moduleTheme?: Partial<GeneratedThemeShade>;

    /**
     * The headline
     */
    headline?: string;

    /**
     * Which variant the headline is
     */
    headlineVariant?: TextVariants;

    /**
     * The bodytext
     */
    text?: string;

    /**
     * Link
     */
    callToAction?: LinkType;

    /**
     * Which variant of button to use. If sizes e.g. are needed, extend this
     */
    buttonVariant?: Exclude<ButtonProps['variant'], undefined>;

    /**
     * Which position the button should take, defaults to trait
     */
    textPosition?: TextBlockForContentCardsLayout;
};

/**
 *
 * @description
 * A block of text that can contain a headline, text and a link in the shape of a button.
 * Useful for typical spots, where an image is present, with some accompanying text. E.g. M20.
 * The text can either be below or ontop of the image.
 */
export const TextBlockForContentCards = ({
    moduleTheme: initialModuleTheme,
    headline,
    text,
    callToAction,
    buttonVariant,
    headlineVariant = 'display4',
    textPosition,
}: TextBlockForContentCardsProps) => {
    const { traits } = useTheme();
    // This block can be either ontop of contentCards or below. This is decided on a themebasis.
    const position =
        textPosition || (traits.contentCard.textPosition as TextBlockForContentCardsLayout);
    const isTextOnContent = position === 'on';

    const moduleTheme: Partial<GeneratedThemeShade> = {
        ...initialModuleTheme,
        // If the text is ontop of content, always choose the inverted shades
        ...(isTextOnContent && {
            headlineShade: 'headlineInverted',
            textShade: 'paragraphInverted',
            buttonShade: 'light',
        }),
    };

    return (
        <StyledTextBlockForContentCards
            position={position}
            backgroundColor={moduleTheme.backgroundColor}
        >
            {headline && (
                <h3>
                    <StrokeText
                        text={headline}
                        variant={headlineVariant}
                        shade={moduleTheme?.headlineShade}
                    />
                </h3>
            )}
            {text && <Text children={text} shade={moduleTheme?.textShade} variant="bodySm" />}
            {callToAction?.url && (
                <RouterLink href={callToAction.url}>
                    <Button
                        as="a"
                        target={callToAction.target}
                        title={callToAction.title}
                        variant={buttonVariant}
                        children={callToAction.text}
                        shade={moduleTheme?.buttonShade}
                    />
                </RouterLink>
            )}
        </StyledTextBlockForContentCards>
    );
};

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { cover, shouldNotForwardProps } from '~/shared/utils/styled';

const imageFadeEasing = 'cubic-bezier(0.19, 1, 0.22, 1)';
const imageFadeDurationToken = 'slow01';

export type StyledImageWrapperProps = {
    /**
     * If the image is currently loading
     */
    isLoading?: boolean;
    /**
     * Which color skeleton to show while image loads.
     * if "onLoadAnimation" is 'fade', the skeletonShade will fade out, while the image fades in.
     * This is useful to create a smoother transition, instead of a flash of the page background
     */
    skeletonShade?: 'dark' | 'light' | 'none';

    /**
     * The type of load animation the image has, defaults to 'fade'
     */
    onLoadAnimation?: 'fade' | 'swipe' | 'none';

    /**
     * Sets the hover style of images.
     * The hover will only show if wrapped by an anchor, idle will depending on prop.
     * 'idle' means only the idle state is visible
     * 'active' means only the active state is visible
     * 'none' means neither are visible (default)
     * 'both' means both are visible
     */
    hoverStyle?: 'idle' | 'active' | 'none' | 'both';

    /**
     * Enable this to invert the color of the image
     */
    invert?: boolean;
};

export const StyledImageWrapper = styled('span', {
    shouldForwardProp: shouldNotForwardProps(['isLoading', 'onLoadAnimation']),
})<StyledImageWrapperProps>(
    {
        position: 'relative',
        display: 'block',
        fontSize: 0,
        height: '100%',

        '&:before': {
            content: '""',
            ...cover,
        },
    },

    switchProp('skeletonShade', {
        light: ({ theme, isLoading, onLoadAnimation }) => ({
            '&:before': {
                backgroundColor: isLoading ? theme.colors.light30 : 'transparent',
                transition:
                    onLoadAnimation === 'fade'
                        ? `background-color
                        ${imageFadeEasing}
                        ${theme.animations.getDuration(imageFadeDurationToken)}`
                        : '',
            },
        }),
        dark: ({ theme, isLoading, onLoadAnimation }) => ({
            '&:before': {
                backgroundColor: isLoading ? theme.colors.light50 : 'transparent',
                transition:
                    onLoadAnimation === 'fade'
                        ? `background-color
                        ${imageFadeEasing}
                        ${theme.animations.getDuration(imageFadeDurationToken)}`
                        : '',
            },
        }),
    }),

    switchProp('onLoadAnimation', {
        fade: ({ theme, isLoading }) => ({
            img: {
                opacity: isLoading ? 0 : 1,
                transition: `opacity ${imageFadeEasing} ${theme.animations.getDuration(
                    imageFadeDurationToken
                )}`,
            },
        }),
        swipe: ({ theme, isLoading }) => ({
            // Initial clip-path, to ensure imagewrapper is hidden before animation triggers
            clipPath: `inset(0 100% 0 0)`,
            transformOrigin: 'left',
            animationFillMode: 'forwards',
            animationDuration: theme.animations.getDuration('slow02'),
            animationTimingFunction: theme.animations.getEasing('reversible', 'expressive'),
            animationName: isLoading
                ? 'none'
                : keyframes({
                      '0%': { clipPath: 'inset(0 100% 0 0)' },
                      '100%': { clipPath: 'inset(0 0% 0 0)' },
                  }),
            img: {
                transformOrigin: 'left',
                animationFillMode: 'forwards',
                animationDuration: theme.animations.getDuration('slow02'),
                animationTimingFunction: theme.animations.getEasing('reversible', 'expressive'),
                // Next image won't trigger loading if the image has been scaled up before load.
                // We instead scale up very fast, then scale down
                animationName: isLoading
                    ? 'none'
                    : keyframes({
                          '0%': { transform: 'scale(1)' },
                          '1%': { transform: 'scale(1.2)' },
                          '100%': { transform: 'scale(1)' },
                      }),
            },
        }),
    }),

    ifProp(
        ({ hoverStyle }) => ['idle', 'active', 'both'].includes(hoverStyle || ''),
        ({ theme: { animations, traits }, isLoading, hoverStyle }) => {
            const showOnIdle = ['idle', 'both'].includes(hoverStyle || '');
            const showOnActive = ['active', 'both'].includes(hoverStyle || '');

            return {
                isolation: 'isolate',
                transitionProperty: 'box-shadow',
                transitionDuration: animations.getDuration('moderate02'),
                transitionTimingFunction: animations.getEasing('standard', 'expressive'),
                boxShadow: showOnIdle ? traits.image.hover.boxShadowIdle : '',
                img: {
                    mixBlendMode: 'multiply',
                },
                '&:after': {
                    content: '""',
                    ...cover,
                    backgroundColor:
                        showOnIdle && !isLoading
                            ? traits.image.hover.backgroundColorIdle
                            : 'transparent',
                    transitionProperty: 'background-color',
                    transitionDuration: 'inherit',
                    transitionTimingFunction: 'inherit',
                },
                ...(showOnActive && {
                    'a:hover &': {
                        boxShadow: traits.image.hover.boxShadowActive,
                        '&:after': {
                            content: '""',
                            backgroundColor: traits.image.hover.backgroundColorActive,
                        },
                    },
                }),
            };
        }
    ),

    ifProp(
        { invert: true },
        {
            img: {
                filter: 'invert(1)',
            },
        }
    )
);

import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { linearBackground } from '~/shared/utils/styled';
import { TextBlockForContentCardsLayout } from './TextBlockForContentCards';

export const StyledTextBlockForContentCards = styled.div<{
    position: TextBlockForContentCardsLayout;
    backgroundColor?: string;
}>(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spaces[2],
        whiteSpace: 'pre-line',
    }),
    switchProp('position', {
        on: ({ theme, backgroundColor }) => ({
            ...linearBackground(backgroundColor),
            inset: 'auto 0 0 0',
            padding: `${theme.spaces[5]} ${theme.spaces[3]} ${theme.spaces[3]}`,
        }),
        below: ({ theme }) => ({
            marginTop: theme.spaces[2],
        }),
    })
);

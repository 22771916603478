import { Text, TextProps, TextShade } from '$shared/components/Text';
import { useModule } from '~/templates';
import { usePage } from '~/templates/pages';

export type HeadlineProps = TextProps & {
    children?: React.ReactNode;
    shade?: TextShade;
};

export const Headline = (props: HeadlineProps) => {
    const { index = 0 } = useModule();
    const { hiddenH1 } = usePage();
    const hasHiddenHeadline = Boolean(hiddenH1);

    const headlineTag = index === 0 && !hasHiddenHeadline ? 'h1' : 'h2';
    return <Text as={props.as ?? headlineTag} shade={props.shade} {...props} />;
};

import { ImageLoaderProps } from 'next/legacy/image';

export const defaultLoader = ({ src, ...rest }: ImageLoaderProps) => {
    const url = new URL(src);

    Object.entries(rest).forEach(([key, value]) => {
        if (value !== undefined) {
            url.searchParams.append(key, value.toString());
        }
    });

    return url.toString();
};
